import React from "react"
import { Region, LazyImage } from "../../ui"

const ArticleLayout = ({ entity, language }) => (
    <>
        <div className="region-hero">
            <div className="region-in">
                <div className="field field-hero slim">
                    <div className="bg">
                        <LazyImage
                            className="field img field-img"
                            src={{ url: "/upload/node/img/2017/08/21/42-lg-l-1.jpg" }}
                            bg
                        />
                    </div>
                </div>
            </div>
        </div>
        <Region entity={entity} language={language} dynamic />
    </>
)

export default React.memo(ArticleLayout)
