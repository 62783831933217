import React from "react"
import Captcha from "../../Captcha"

const FormFieldCaptcha = ({ domRef, fieldValue, dispatch, info, value, ...props }) => {
    const handleChange = val => {
        //console.log(value)
        dispatch({
            type: "change",
            field: info.field,
            label: value.label,
            fieldType: value.fieldType,
            value: val,
        })
    }

    return (
        <div ref={domRef} {...props}>
            <Captcha onChange={handleChange} language={info.language} />
        </div>
    )
}
export default FormFieldCaptcha
