import React from "react"
import svgIconsMap from "./icons"
import faIcons from "./fa-icons"
import { FaIcon, Icon } from "../ui"

const icons = [
    { label: "Fără iconiță" },
    ...Object.keys(faIcons)
        .sort()
        .map(icon => ({ val: icon, label: () => <FaIcon icon={icon} size={24} /> })),
]
const svgIcons = Object.keys(svgIconsMap)
    .sort()
    .map(icon => ({ val: icon, label: () => <Icon name={icon} /> }))

const fieldTypes = {
    img: {
        arrowRenderers: [
            () => <Icon name="ArrowLeft" size={48} />,
            () => <Icon name="ArrowRight" size={48} />,
        ],
        isEmpty: value => !value || value.length === 0,
    },
    text: {
        name: "Text simplu",
        dynamic: false,
        isEmpty: value => !value || (typeof value === "string" && value.trim() === ""),
    },
    html: {
        name: "Text",
        settings: [{ name: "wrapper", type: "bool", label: "Wrapper", widget: "toggle" }],
        isEmpty: value =>
            !value ||
            (typeof value === "string"
                ? value.trim() === "" || value.trim() === "<p></p>"
                : value.raw
                ? !value.raw.blocks ||
                  value.raw.blocks.reduce((acc, block) => `${acc}${block.text.trim()}`, "") === ""
                : !value.document),
    },
    bool: { dynamic: false },
    //img: {  },
    class: { dynamic: false },
    radio: { dynamic: false },
    list: { dynamic: false },
    obj: { dynamic: false },
    custom: { dynamic: false },

    welcome: {
        name: "Welcome",
        type: "obj",
        isBlock: true,
        _nowrap: true,
        fields: [
            { name: "slogan1", type: "html", label: "Slogan1" },
            { name: "slogan2", type: "html", label: "Slogan2" },
            { name: "bg", type: "img", label: "Fundal" },
        ],
        //settings: [{ name: "_o", type: "bool", label: "Orizontal", widget: "toggle" }],
        renderer: "Welcome",
        getLabel: () => "Welcome",
        delayed: true,
        maxDelay: 500,
    },

    "flota-numbers": {
        name: "Flota Numbers",
        type: "obj",
        _nowrap: true,
        fields: [
            { name: "text", type: "html", label: "Text" },
            { name: "bg", type: "img", label: "Imagine Fond" },
            { name: "n1", type: "text", label: "Număr" },
            { name: "label1", type: "text", label: "Etichetă", t: true },
            { name: "n2", type: "text", label: "Număr" },
            { name: "label2", type: "text", label: "Etichetă", t: true },
        ],
        renderer: "FlotaNumbers",
        getLabel: () => "FlotaNumbers",
        delayed: true,
        maxDelay: 5000,
    },
    department: {
        name: "Departament",
        type: "obj",
        _nowrap: true,
        fields: [{ name: "name", type: "text", label: "Nume" }],
        renderer: "Department",
        getLabel: val => val.name,
    },
    subdepartment: {
        name: "Subdepartament",
        type: "obj",
        _nowrap: true,
        fields: [
            { name: "name", type: "text", label: "Departament" },
            { name: "groupEmail", type: "text", label: "Group E-mail" },
            { name: "nrGeneral", type: "text", label: "Numar general" },
            { name: "head", type: "text", label: "Head of Department" },
            { name: "email", type: "text", label: "E-mail" },
            { name: "mobile", type: "text", label: "Mobile" },
            { name: "obs", type: "text", label: "Observații" },
        ],
        renderer: "Subdepartment",
        getLabel: val => val.name,
    },
    "recovery-truck-welcome": {
        name: "Recovery Welcome",
        type: "obj",
        _nowrap: true,
        fields: [
            { name: "slogan", type: "html", label: "Slogan" },
            { name: "hero1", type: "html", label: "Slogan Hero 1" },
            { name: "hero2", type: "html", label: "Slogan Hero 2" },
            { name: "slides", type: "img", label: "Slides" },
            { name: "bg", type: "img", label: "Fundal" },
        ],
        renderer: "RecoveryTruckWelcome",
        getLabel: () => "Recovery Welcome",
    },
    "recovery-description2": {
        name: "Recovery Description 2",
        type: "obj",
        _nowrap: true,
        fields: [
            { name: "title", type: "text", label: "Titlu (linia 1)" },
            { name: "subtitle", type: "text", label: "Titlu (linia 2)" },
            { name: "bg", type: "img", label: "Fundal", _nowrap: true },
            { name: "text", type: "html", label: "Text (para 1)" },
            { name: "text2", type: "html", label: "Text (para 2)" },
        ],
        renderer: "RecoveryDescription2",
        getLabel: val => val.title,
        _visibility: true,
    },
    "recovery-services": {
        name: "Recovery Services",
        type: "obj",
        _nowrap: true,
        fields: [
            { name: "title", type: "text", label: "Titlu" },
            { name: "services", type: "html", label: "Servicii" },
            { name: "bg", type: "img", label: "Fundal", _nowrap: true },
        ],
        renderer: "RecoveryServices",
        getLabel: val => val.title,
    },
    "recovery-call": {
        name: "Recovery Services",
        type: "obj",
        _nowrap: true,
        fields: [
            { name: "title", type: "text", label: "Titlu" },
            { name: "text", type: "html", label: "Text" },
            { name: "phone", type: "text", label: "Telefoane" },
            { name: "bg", type: "img", label: "Fundal", _nowrap: true },
        ],
        renderer: "RecoveryCall",
        getLabel: val => val.title,
        _visibility: true,
    },
    "recovery-contact": {
        name: "Recovery Contact",
        type: "obj",
        _nowrap: true,
        fields: [
            { name: "title", type: "text", label: "Titlu" },
            { name: "text", type: "html", label: "Text" },
            { name: "phone", type: "text", label: "Telefon" },
            { name: "name", type: "text", label: "Nume" },
            { name: "company", type: "text", label: "Companie" },
            { name: "email", type: "text", label: "E-mail" },
            { name: "phone2", type: "text", label: "Telefon" },
            { name: "message", type: "text", label: "MEsaj" },
            { name: "send", type: "text", label: "Trimite" },
            { name: "sending", type: "text", label: "Se trimite" },
            { name: "sent", type: "html", label: "A fost trimis" },
        ],
        renderer: "RecoveryContact",
        getLabel: val => val.title,
    },
    contact: {
        name: "Contact",
        type: "obj",
        _nowrap: true,
        fields: [
            { name: "name", type: "text", label: "Nume", t: true },
            { name: "company", type: "text", label: "Companie", t: true },
            { name: "email", type: "text", label: "E-mail", t: true },
            { name: "phone", type: "text", label: "Telefon", t: true },
            { name: "message", type: "text", label: "MEsaj", t: true },
            { name: "send", type: "text", label: "Trimite", t: true },
            { name: "sending", type: "text", label: "Se trimite", t: true },
            { name: "sent", type: "html", label: "A fost trimis", t: true },
        ],
        renderer: "Contact",
        getLabel: val => (val ? val.name : ""),
    },
    offer: {
        name: "Oferta",
        type: "obj",
        _nowrap: true,
        fields: [
            { name: "name", type: "text", label: "Nume", t: true },
            { name: "company", type: "text", label: "Companie", t: true },
            { name: "email", type: "text", label: "E-mail", t: true },
            { name: "phone", type: "text", label: "Telefon", t: true },
            { name: "message", type: "text", label: "MEsaj", t: true },
            { name: "send", type: "text", label: "Trimite", t: true },
            { name: "sending", type: "text", label: "Se trimite", t: true },
            { name: "sent", type: "html", label: "A fost trimis", t: true },
        ],
        renderer: "Oferta",
        getLabel: val => val.name,
    },

    block: {
        name: "Container",
        type: "obj",
        isBlock: true,
        _nowrap: true,
        delayed: true,
        maxDelay: 500,
        fields: [
            { name: "label", type: "text", label: "Etichetă" },
            { name: "bg", type: "img", label: "Imagine" },
            { name: "p", type: "bool", label: "Proporțional", widget: "toggle" },
            { name: "f", type: "bool", label: "Fullscreen fixed", widget: "toggle" },
            { name: "h", type: "bool", label: "Orizontal", widget: "toggle" },
        ],
        settings: [
            { name: "border", type: "bool", label: "Border" },
            { name: "bgColor", type: "text", label: "Culoare fond" },
            { name: "bg", type: "img", label: "Fundal", single: true },
            {
                name: "bgProp",
                type: "bool",
                label: "Dimensiuni proportionale cu fundalul",
                single: true,
            },
        ],
        renderer: "Block",
        getLabel: val => val.label,
    },
    image: {
        name: "Slideshow",
        type: "obj",
        _nowrap: true,
        fields: [
            { name: "text", type: "html", label: "Text", t: true },
            { name: "img", type: "img", label: "Imagine", single: false },
            { name: "multiple", type: "bool", label: "Multiple" },
            { name: "carousel", type: "bool", label: "Carusel" },
            { name: "bg", type: "img", label: "Fundal", single: true },
        ],
        renderer: "Image",
    },
    hero: {
        name: "Hero",
        type: "obj",
        isBlock: true,
        _nowrap: true,
        fields: [
            {
                name: "size",
                type: "radio",
                values: [
                    { val: "full", label: "Intreg ecranul" },
                    { val: "bg-prop", label: "Proportional" },
                    { val: "slim", label: "Slim" },
                ],
                label: "Mărime",
            },
            { name: "img", type: "img", label: "Imagine", single: false },
            { name: "h1", type: "html", label: "Supratitlu", t: true },
            { name: "h2", type: "html", label: "Titlu", t: true },
            { name: "h3", type: "html", label: "Subtitlu", t: true },
            { name: "text", type: "html", label: "Text", t: true },
        ],
        region: "hero",
        renderer: "Hero",
    },
    card: {
        name: "Card",
        type: "obj",
        _nowrap: true,
        delayed: true,
        maxDelay: 5000,
        fields: [
            {
                name: "align",
                type: "select",
                label: "Aliniere",
                values: [
                    {
                        val: "top",
                        label: "Imagine sus",
                    },
                    {
                        val: "left",
                        label: "Imagine stânga",
                    },
                    {
                        val: "right",
                        label: "Imagine dreapta",
                    },
                ],
            },
            { name: "contain", type: "bool", label: "Imagine încadrată" },
            { name: "big", type: "bool", label: "Text mare" },
            { name: "icon", type: "fa-icon", label: "Iconiță" },
            { name: "text", type: "html", label: "Text", t: true },
            { name: "video", type: "video", label: "Video" },
            { name: "vid", type: "vid", label: "Video", _nowrap: true },
            { name: "img", type: "img", label: "Imagine", single: false },
            { name: "call", type: "text", label: "Chemare", t: true },
            { name: "link", type: "href", label: "Legătură", t: true },
            { name: "bgColor", type: "text", label: "Culoare fond" },
            { name: "bg", type: "img", label: "Fundal", single: true },
            {
                name: "bgProp",
                type: "bool",
                label: "Dimensiuni proportionale cu fundalul",
                single: true,
            },
        ],
        renderer: "Card",
    },
    "fa-icon": {
        name: "Font Awesome Icon",
        type: "select",
        values: icons,
        label: "Iconiță",
        renderer: "FaIcon",
        isEmpty: value => !value || value.trim() === "",
    },
    link: {
        name: "Legătură",
        type: "obj",
        fields: [
            { name: "label", type: "text", label: "Etichetă" },
            { name: "path", type: "href", label: "Legătură" },
        ],
        isEmpty: value => !value || value.link === "",
        _nowrap: true,
        renderer: "LinkRenderer",
    },
    href: {
        name: "Legătură",
        type: "text",
        isEmpty: value => !value || value === "",
        _nowrap: true,
        renderer: "Link",
    },
    action: {
        name: "Action",
        type: "obj",
        fields: [
            { name: "icon", type: "select", values: icons, label: "Iconiță" },
            { name: "call", type: "text", label: "Etichetă" },
            { name: "link", type: "href", label: "Legătură" },
            //{ name: "t", type: "text", label: "Etichetă" },
            //{ name: "l", type: "link", label: "Legătură" },
        ],
        isEmpty: value => !value || !value.link || value.link === "",
        _nowrap: true,
        renderer: "Action",
    },
    view: {
        name: "Vizualizare",
        type: "obj",
        fields: [
            { name: "title", type: "text", label: "Titlu" },
            { name: "view", type: "text", label: "Vizualizare" },
            { name: "query", type: "text", label: "Interogare" },
            { name: "projection", type: "text", label: "Proiecție" },
            { name: "sort", type: "text", label: "Ordonare" },
            { name: "limit", type: "text", label: "Limită" },
            { name: "pageSize", type: "text", label: "Pagină" },
            { name: "moreLink", type: "href", label: "Legătură 'Mai mult...'" },
            { name: "moreText", type: "text", label: "Text 'Mai mult...'" },
        ],
        //isEmpty: value => !value || !value.path || value.path === "",
        _nowrap: true,
        renderer: "View",
    },
    icon: {
        name: "Iconiță",
        type: "obj",
        fields: [
            { name: "img", type: "img", label: "Imagine", single: true },
            { name: "text", type: "html", label: "Text" },
        ],
        //isEmpty: value => !value || !value.path || value.path === "",
        _nowrap: true,
        renderer: "Icon",
    },
    iconCard: {
        name: "Card cu iconiță",
        type: "obj",
        fields: [
            { name: "icon", type: "select", values: svgIcons, label: "Iconiță" },
            { name: "title", type: "text", label: "Titlu" },
            { name: "text", type: "html", label: "Text" },
        ],
        //isEmpty: value => !value || !value.path || value.path === "",
        _nowrap: true,
        renderer: "IconCard",
    },
    cardList: {
        name: "Card cu iconiță",
        type: "list",
        items: "iconCard",
        //isEmpty: value => !value || !value.path || value.path === "",
        _nowrap: true,
        renderer: "IconCardList",
    },
    minicard: {
        name: "Card cu iconiță",
        type: "obj",
        fields: [
            { name: "title", type: "text", label: "Titlu" },
            { name: "icon", type: "select", values: svgIcons, label: "Iconiță" },
            { name: "name", type: "text", label: "Nume" },
            { name: "phone", type: "text", label: "Telefon" },
            { name: "mail", type: "text", label: "E-mail" },
        ],
        //isEmpty: value => !value || !value.path || value.path === "",
        _nowrap: true,
        renderer: "Minicard",
    },
    minicardList: {
        name: "Lista Minicard cu iconiță",
        type: "list",
        items: "minicard",
        //isEmpty: value => !value || !value.path || value.path === "",
        _nowrap: true,
        renderer: "MinicardList",
    },
    doc: {
        name: "Document",
        type: "doc",
        //isEmpty: value => !value || !value.path || value.path === "",
        _nowrap: true,
        renderer: "Document",
    },
    teaser: {
        name: "Teaser",
        type: "obj",
        //isEmpty: value => !value || !value.path || value.path === "",
        fields: [
            { name: "title", type: "text", label: "Titlu", t: true },
            { name: "summary", type: "html", label: "Sumar", t: true },
            { name: "thumb", type: "img", label: "Miniatură", single: true },
            { name: "link", type: "href", label: "Legătură", t: true },
            { name: "bgColor", type: "text", label: "Culoare fond" },
            { name: "bg", type: "img", label: "Fundal", single: true },
            {
                name: "bgProp",
                type: "bool",
                label: "Dimensiuni proportionale cu fundalul",
                single: true,
            },
        ],
        getLabel: val => val.title,
        _nowrap: true,
        renderer: "Teaser",
    },
}

export { fieldTypes }
