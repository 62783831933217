import React, { useState, useRef, useCallback, useMemo } from "react"
import renderers from "./renderers"
import { useSubscriptionProvider, useIntersection } from "../lib"
import { fieldTypes } from "../admin/auth/conf/field"
import Delayed from "./Delayed"

const Field = props => {
    const {
        domRef,
        info,
        field,
        tag,
        className,
        noadmin,
        rendererComp,
        renderer,
        _nowrap,
        _editParent,
        children,
        ...other
    } = props
    const localRef = useRef()
    const localDomRef = domRef || localRef
    const mounted = useRef(true)
    const visible = useRef()
    const parents = useRef()
    const [localState, localSetState] = useState()
    const localInfo = useMemo(() => {
        if (!info) {
            console.log(`Field ${field} has no INFO.`)
            return {}
        }
        const { user, entity, language } = info
        const parent = info.value
        const parentFieldInfo = info.fieldInfo
        const fieldName = info.fieldName ? `${info.fieldName}.${field}` : field
        const fieldInfo = parent
            ? entity.childFieldInfo(parent, parentFieldInfo, field)
            : entity.fieldInfo(fieldName)
        const rawValue = parent
            ? entity.getChildValue(parent, parentFieldInfo, field, language)
            : entity.getValue(fieldName, language)
        const value =
            fieldInfo && fieldInfo.prepareView ? fieldInfo.prepareView(rawValue) : rawValue
        const states = fieldInfo ? fieldInfo.states || info.states : info.states
        const state = fieldInfo && fieldInfo.states ? localState : info.state
        const setState = fieldInfo && fieldInfo.states ? localSetState : info.setState
        if (user && !noadmin && entity && entity._id) {
            if (_editParent) {
                parents.current = [...(info.parents ? info.parents.current : [])]
                if (parents.current.length > 0)
                    parents.current[parents.current.length - 1] = Object.assign(
                        {},
                        parents.current[parents.current.length - 1],
                        { domRef: localDomRef }
                    )
            } else
                parents.current = [
                    ...(info.parents ? info.parents.current : []),
                    {
                        domRef: localDomRef,
                        entity,
                        fieldInfo,
                        field: fieldName,
                        states,
                        state,
                        setState,
                    },
                ]
        }
        //console.log(field, parent, parentFieldInfo, fieldInfo, conf, value)
        return {
            domRef: localDomRef,
            user,
            entity,
            language,
            fieldName,
            field,
            fieldInfo,
            value,
            parents,
            states,
            state,
            setState,
        }
    }, [info, localState, localDomRef, noadmin, _editParent, field])
    const { user, entity, fieldInfo, value } = localInfo
    const setFieldAdmin = useRef(useSubscriptionProvider("fieldAdmin"))

    const mouseOver = useRef(false)
    const onMouseOver = useCallback(() => {
        //console.log("mouse over", parents.current)
        if (mouseOver.current) return

        mouseOver.current = true
        //console.log(parents.current)
        setFieldAdmin.current({ mouse: "enter", fieldLine: parents.current })
    }, [])

    const onMouseLeave = useCallback(() => {
        //console.log("mouse leave")
        mouseOver.current = false
        setFieldAdmin.current({ mouse: "leave", fieldLine: parents.current })
    }, [])
    //console.log(field, conf, confLocal)
    //console.log(language, entity, field, fieldInfoLocal, val)
    const Renderer =
        props.renderer ||
        (fieldInfo
            ? fieldInfo.renderer
                ? renderers[fieldInfo.renderer]
                : renderers[fieldInfo.typeName] ||
                  renderers[fieldInfo.name] ||
                  (fieldTypes[fieldInfo.typeName]
                      ? fieldTypes[fieldInfo.typeName].renderer
                          ? renderers[fieldTypes[fieldInfo.typeName].renderer]
                          : null
                      : null) ||
                  null
            : null)

    const onIntersect = useCallback(
        entry => {
            if (!mounted.current || visible.current || !entry.isIntersecting) return
            visible.current = true
            localDomRef.current.classList.add(
                fieldInfo && fieldInfo.classVisible ? fieldInfo.classVisible : "visible"
            )
            //setVisible(true)
        },
        [visible, fieldInfo, localDomRef]
    )
    useIntersection(
        fieldInfo && fieldInfo._visibility && Renderer ? localDomRef : null,
        onIntersect
    )

    if (
        fieldInfo._translations &&
        fieldInfo._translations.length > 0 &&
        !fieldInfo._translations.includes(localInfo.language)
    )
        return null

    if (fieldInfo && fieldInfo._hidden && !user) return null
    if (!fieldInfo || fieldInfo.display === false) return null
    if (fieldInfo.isEmpty && fieldInfo.isEmpty(value)) return null
    if (!Renderer) {
        console.log("Renderer not found for ", field, fieldInfo, value, entity)
        return null
    }

    //if (info.states) console.log(info.state, conf)
    //if (info.states) console.log(info.state, info.states, fieldInfo)
    if (
        info.states &&
        fieldInfo.state &&
        fieldInfo.state.indexOf(info.state || info.states[0].val) < 0
    )
        return null

    //const entityConf = entity ? entity._conf || {} : {}
    //const fieldConf = conf || entity ? entity.getConf(field) || {} : {}
    //console.log(field, fieldConf)

    const childFields =
        value && value._e
            ? value._o
                ? value._o
                      .split(",")
                      .filter(f => Object.keys(value._e).indexOf(f) >= 0 && !!value._e[f].type)
                : Object.keys(value._e).filter(f => !!value._e[f].type)
            : []
    //console.log(field, fieldInfo, value, childFields)
    //? Object.keys(entityConf).filter(f => entityConf[f] && entityConf[f]._block === field)
    //: []

    const wrapperProps = {}
    //console.log(field, fieldInfoLocal)
    if (fieldInfo.id) wrapperProps.id = fieldInfo.id
    let classes =
        `field ${field.split(".").pop()} field-${fieldInfo.typeName || fieldInfo.type} ` +
        (fieldInfo._class ? fieldInfo._class : "") +
        (className || "")

    //if (visible) classes += " field-visible"
    if (visible.current) classes += ` ${fieldInfo.classVisible || "visible"}`

    wrapperProps.className = classes

    //if (data && user) {
    //console.log("add  mouse", user && !noadmin && entity)
    if (user && !noadmin && entity && entity._id) {
        //console.log("add  mouse")
        //if (!parents.current)
        //setupLineage()
        wrapperProps.onMouseOver = onMouseOver
        wrapperProps.onMouseLeave = onMouseLeave
    }

    if (fieldInfo.delayed) {
        if (_nowrap || fieldInfo._nowrap) {
            if (children || childFields.length > 0) {
                return (
                    <Delayed maxDelay={fieldInfo.maxDelay}>
                        <Renderer
                            {...wrapperProps}
                            {...other}
                            domRef={localDomRef}
                            info={localInfo}
                            value={value}
                        >
                            {children}
                            {childFields &&
                                childFields.map((f, i) => (
                                    <Field key={i} info={localInfo} field={f} />
                                ))}
                        </Renderer>
                    </Delayed>
                )
            }
            return (
                <Delayed maxDelay={fieldInfo.maxDelay}>
                    <Renderer
                        {...wrapperProps}
                        {...other}
                        domRef={localDomRef}
                        info={localInfo}
                        value={value}
                    />
                </Delayed>
            )
        }
        const Tag = tag || fieldInfo.tag || "div"
        if (children || childFields.length > 0) {
            return (
                <Delayed maxDelay={fieldInfo.maxDelay}>
                    <Tag ref={localDomRef} {...wrapperProps}>
                        {fieldInfo && fieldInfo.pre && fieldInfo.pre()}
                        <Renderer {...other} info={localInfo} value={value}>
                            {children}
                            {childFields &&
                                childFields.map((f, i) => (
                                    <Field key={i} info={localInfo} field={f} />
                                ))}
                        </Renderer>
                    </Tag>
                </Delayed>
            )
        }
        return (
            <Delayed maxDelay={fieldInfo.maxDelay}>
                <Tag ref={localDomRef} {...wrapperProps}>
                    {fieldInfo && fieldInfo.pre && fieldInfo.pre()}
                    <Renderer {...other} info={localInfo} value={value} />
                </Tag>
            </Delayed>
        )
    }

    //console.log("field", localInfo.fieldName, value)
    if (_nowrap || fieldInfo._nowrap) {
        if (children || childFields.length > 0) {
            return (
                <Renderer
                    {...wrapperProps}
                    {...other}
                    domRef={localDomRef}
                    info={localInfo}
                    value={value}
                >
                    {children}
                    {childFields &&
                        childFields.map((f, i) => <Field key={i} info={localInfo} field={f} />)}
                </Renderer>
            )
        }
        return (
            <Renderer
                {...wrapperProps}
                {...other}
                domRef={localDomRef}
                info={localInfo}
                value={value}
            />
        )
    }
    const Tag = tag || fieldInfo.tag || "div"
    if (children || childFields.length > 0) {
        return (
            <Tag ref={localDomRef} {...wrapperProps}>
                {fieldInfo && fieldInfo.pre && fieldInfo.pre()}
                <Renderer {...other} info={localInfo} value={value}>
                    {children}
                    {childFields &&
                        childFields.map((f, i) => <Field key={i} info={localInfo} field={f} />)}
                </Renderer>
            </Tag>
        )
    }
    return (
        <Tag ref={localDomRef} {...wrapperProps}>
            {fieldInfo && fieldInfo.pre && fieldInfo.pre()}
            <Renderer {...other} info={localInfo} value={value} />
        </Tag>
    )
}

export default React.memo(Field)
