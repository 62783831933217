const nodeBundles = {
    home: {
        name: "Homepage",
        info: "Homepage",
    },
    page: {
        name: "Pagină",
        info: "Pagină generică",
        searchField: "title",
        //dynamicFields: ["html", "quote", "block", "icon", "img", "video"],
        fields: {
            title: { fullWidth: true },
        },
        fieldCheck: {
            title: [value => value && value.trim() !== "", "Titlul nu poate fi gol."],
            //gallery: [value => checkImage(value), "În galerie se pot adăuga doar imagini."],
            //thumb: [value => checkImage(value), "Miniatura trebuie să fie o imagine."],
        },
    },
    article: {
        name: "Articol",
        info: "Articol",
        searchField: "title",
        //dynamicFields: ["html", "quote", "block", "icon", "img", "video"],
        fields: {
            title: { fullWidth: true },
        },
        fieldCheck: {
            title: [value => value && value.trim() !== "", "Titlul nu poate fi gol."],
            //gallery: [value => checkImage(value), "În galerie se pot adăuga doar imagini."],
            //thumb: [value => checkImage(value), "Miniatura trebuie să fie o imagine."],
        },
    },
}

const entityTypes = {
    job: {
        name: "Loc de muncă",
        collection: "job",
    },
    footer: {
        name: "Subsol",
        collection: "site",
    },
}
export { nodeBundles, entityTypes }
