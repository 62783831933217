import React from "react"
import Region from "../../ui/Region"

const PageLayout = ({ entity, language }) => (
    <>
        <Region region="hero" entity={entity} language={language} />
        <Region entity={entity} language={language} dynamic />
    </>
)

export default React.memo(PageLayout)
