import { ReactComponent as ArrowLeft } from "./svg/carrousel-arrow-left-1.svg"
import { ReactComponent as ArrowRight } from "./svg/carrousel-arrow-right-1.svg"

import { ReactComponent as Plus } from "./svg/plus.svg"

const icons = {
    ArrowLeft,
    ArrowRight,
    Plus,
}
export default icons
