import React from "react"
import { Field, FaIcon } from "../../ui"

const Action = ({ info, value, className }) => {
    if (!value) return null

    /*if (value.t)
        return (
            <Field info={info} field="l" className={className} _editParent>
                <Field info={info} field="t" tag="span" noadmin />
            </Field>
        )*/

    return (
        <Field info={info} field="link" className={className} _editParent>
            <Field info={info} field="call" tag="span" noadmin />
            {value.icon && <FaIcon icon={value.icon} />}
        </Field>
    )
}
export default Action
