import React from "react"
import { Field } from "../../../ui"

const RecoveryServices = ({ domRef, info, value, ...other }) => {
    return (
        <div ref={domRef} {...other}>
            <Field info={info} field="bg" bg />
            <div className="block-in">
                <Field info={info} field="title" tag="h2" />
                <Field
                    info={info}
                    field="services"
                    iconBefore={{ li: { icon: "faCheckCircle", size: 48 } }}
                />
            </div>
        </div>
    )
}
export default RecoveryServices
