import React from "react"
import { Entity, Field, Link } from "../../../ui"
const more = {
    en: "Read more",
    de: "Weiterlesen",
    ro: "Citește",
}
const PathLink = ({ info, children, ...props }) => {
    const path = info.entity.path.filter(p => p.lang === info.language).shift()

    return path ? (
        <Link to={path.p} {...props}>
            {children}
        </Link>
    ) : (
        children
    )
}
const NewsRowRenderer = ({ info, row, index }) => (
    <Entity info={info} entity={row} className={index % 2 === 0 ? "even" : "odd"}>
        {info => (
            <>
                <div className="article-thumb">
                    <PathLink info={info} className="">
                        <Field info={info} field="thumb" />
                    </PathLink>
                </div>

                <div className="article-content">
                    <div className="text-wrap">
                        <PathLink info={info} className="title">
                            <Field info={info} field="title" tag="h2" />
                        </PathLink>
                        <PathLink info={info} className="button">
                            {more[info.language]}...
                        </PathLink>
                    </div>
                </div>
            </>
        )}
    </Entity>
)
export default NewsRowRenderer
