import React, { useRef } from "react"
import { useScreen } from "../../lib"
import { Field } from "../../ui"
import { realPath } from "../../lib/util"

const Card = ({ domRef, className, value, info, ...props }) => {
    //console.log(value)
    const textRef = useRef()
    const carouselProps = {}
    const screen = useScreen()
    const noText = info.entity
        .childFieldInfo(value, info.fieldInfo, "text")
        .isEmpty(info.entity.getChildValue(value, info.fieldInfo, "text", info.language))
    if (!noText) {
        if (screen.MQ !== "xss" && screen.MQ !== "xs" && screen.MQ !== "sm") {
            carouselProps.fillParent = true
            carouselProps.minHeightRef = textRef
        }
    }
    const align = info.entity.getChildValue(value, info.fieldInfo, "align", info.language)
    const video = info.entity.getChildValue(value, info.fieldInfo, "video", info.language)
    const vid = info.entity.getChildValue(value, info.fieldInfo, "vid", info.language)
    const img = info.entity.getChildValue(value, info.fieldInfo, "img", info.language)
    const hasVideo = video || vid

    const classes = `${className} ${
        !value || ((!img || img.length === 0) && !hasVideo) ? "noimage" : "with-image"
    } ${noText ? "notext" : ""} ${align || ""} ${value && value.big ? "big" : ""}`

    const videoProps = {}
    if (vid && img && img.length > 0) videoProps.poster = realPath(img[0].url)
    return (
        <div ref={domRef} className={classes} {...props}>
            <div className="field-in">
                {hasVideo ? (
                    value.video ? (
                        <Field info={info} field="video" />
                    ) : (
                        <Field info={info} field="vid" videoProps={videoProps} />
                    )
                ) : (
                    <Field info={info} field="img" carouselProps={carouselProps} carousel />
                )}
                {!noText && (
                    <div ref={textRef} className="text-content">
                        <div className="text-wrap">
                            <Field info={info} field="icon" />
                            <Field info={info} field="text" />
                        </div>
                    </div>
                )}
            </div>
        </div>
    )
}

export default Card
