import React from "react"
import { Field, FaIcon } from "../../../ui"

const phoneLinks = (info, value) => {
    const phone = info.entity.getChildValue(value, info.fieldInfo, "phone", info.language)
    if (!phone) return null
    const phones = phone.split(",")
    return (
        <div className="numbers">
            <FaIcon icon="faMobileAlt" />
            <h2>
                {phones.map((phone, i) => (
                    <div key={i}>
                        <a href={`tel:${phone.split(" ").join("")}`}>{phone}</a>
                    </div>
                ))}
            </h2>
        </div>
    )
}

const RecoveryCall = ({ domRef, info, value, ...other }) => {
    return (
        <div ref={domRef} {...other}>
            <Field info={info} field="title" tag="h2" />
            <div className="block-in">
                <Field info={info} field="bg" bg proportional _nowrap />
                <div className="block-content">
                    {phoneLinks(info, value)}
                    <Field info={info} field="text" />
                </div>
            </div>
        </div>
    )
}
export default RecoveryCall
