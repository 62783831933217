import React from "react"
import { WEBSITE_NAME } from "../../config"
import { realPath } from "../../lib/util"
import { Link } from "../../ui"
import MainMenu from "./MainMenu"
import LanguageSelector from "./LanguageSelector"

const Header = ({ node, language }) => {
    return (
        <header id="header">
            <Link id="logo" className="logo" to="/">
                <img src={realPath("/img/layout/ravitex.png")} alt={WEBSITE_NAME} />
            </Link>

            <div className="header-in menu-full lang-full">
                <MainMenu language={language} />
                <LanguageSelector node={node} language={language} />
            </div>
        </header>
    )
}

export default React.memo(Header)
