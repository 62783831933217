import React, { useContext } from "react"
import { Entity, Field, FaIcon, Auth } from "../../ui"
import { useQuery } from "../../lib"
import { realPath } from "../../lib/util"
import { WEBSITE_NAME } from "../../config"
import AuthContext from "../../admin/AuthContext"
import { useNode } from "../node"

const footerQuery = {
    collection: "site",
    query: { type: "footer" },
}
const footerConfig = { single: true, setEntityInfo: true }

const Footer = () => {
    const [footer] = useQuery(footerQuery, footerConfig)
    const { user } = useContext(AuthContext)
    const language = useNode("language")

    const info = { entity: footer, user, language }
    if (!footer) return null
    return (
        <footer id="footer">
            <Entity info={info} entity={footer}>
                {info => (
                    <div className="footer-in">
                        <div className="column footer-about">
                            <img src={realPath("/img/layout/ravitex.png")} alt={WEBSITE_NAME} />
                            <Field info={info} field="about" />
                        </div>
                        {footer &&
                            footer._e &&
                            Object.keys(footer._e)
                                .filter(
                                    fieldName => footer._info.defaultFields.indexOf(fieldName) < 0
                                )
                                .map((fieldName, i) => (
                                    <div key={i} className="column">
                                        <Field info={info} field={fieldName} />
                                    </div>
                                ))}

                        {footer && (
                            <div className="column">
                                <h3>
                                    <Field info={info} field="menu4link">
                                        <Field info={info} field="menu4label" tag="span" />
                                    </Field>
                                </h3>
                                <div>
                                    <FaIcon icon="faPhone" size={14} />
                                    <Field info={info} field="tel" tag="span" />
                                    <br />
                                    <FaIcon icon="faFax" size={14} />
                                    <Field info={info} field="fax" tag="span" />
                                    <br />
                                    <FaIcon icon="faEnvelope" size={14} />
                                    <Field info={info} field="mail" tag="span" />
                                    <br />
                                    <FaIcon icon="faEnvelope" size={14} />
                                    <Field info={info} field="paddr" tag="span" />
                                    <br />
                                    <br />
                                    <FaIcon icon="faMapMarker" size={14} />
                                    <Field info={info} field="addr" tag="span" />
                                    <br />
                                </div>
                            </div>
                        )}
                    </div>
                )}
            </Entity>
            <div className="copyright">Copyright © 2015-2019 Ravitex</div>

            <Auth />
        </footer>
    )
}
export default React.memo(Footer)
