import { LANGUAGES } from "../../../config"
import { entityFields } from "./entity"
import { nodeBundles, entityTypes } from "./entityAdmin"
import { fieldTypes } from "./field"

//const _isPrimitive = type => fieldTypes[type].type === type
/*
const _firstAlias = fieldInfo => {
    if (!fieldInfo || !fieldTypes[fieldInfo.type]) return fieldInfo


    if (fieldInfo.primitive || fieldTypes[fieldInfo.type].primitive)
        return fieldTypes[fieldInfo.type]
    //fieldInfo
    return _firstAlias(fieldTypes[fieldInfo.type])
    }*/
const _buildFieldInfoRec = fieldInfo => {
    if (!fieldInfo || !fieldTypes[fieldInfo.type] || fieldInfo.primitive) return fieldInfo

    const { name, type, primitive, typeName, ...primitiveFieldInfo } = fieldTypes[fieldInfo.type]
        .primitive
        ? fieldTypes[fieldInfo.type]
        : _buildFieldInfoRec(fieldTypes[fieldInfo.type])
    const info = {
        ...primitiveFieldInfo,
        ...fieldInfo,
        type,
        typeName: fieldInfo.typeName || fieldInfo.type,
        settings: {},
    }
    if (primitiveFieldInfo.settings)
        primitiveFieldInfo.settings.forEach(item => {
            info.settings[item.name] = item
        })
    if (fieldTypes[fieldInfo.type] && fieldTypes[fieldInfo.type].settings)
        fieldTypes[fieldInfo.type].settings.forEach(item => {
            info.settings[item.name] = item
        })
    info.settings = Object.values(info.settings)
    return info
}

export const buildFieldInfo = (fieldInfo, value) => {
    const info = _buildFieldInfoRec(fieldInfo)
    if (!info) return fieldInfo

    if (value && value._e) {
        if (!info.fields) info.fields = []
        let extraFields = []
        Object.keys(value._e).forEach(fieldName => {
            let exist = false
            info.fields = info.fields.map(field => {
                if (field.name === fieldName) {
                    exist = true
                    return { ...field, ...value._e[fieldName] }
                }
                return field
            })
            if (!exist)
                extraFields.push(
                    buildFieldInfo({
                        ...value._e[fieldName],
                        name: fieldName,
                        typeName: value._e[fieldName].type,
                    })
                )
        })
        info.fields = [...info.fields, ...extraFields]
    }
    //console.log(fieldInfo, value, info)
    return info
}

/*

fields: static|dynamic

fieldInfo = {
    ...primitiveInfo,
    ...typeInfo,
    settings: [...primitiveInfo.settings, ...typeInfo.settings]
}

entity: {
    fieldName1: value,
    fieldName2: [
        valueA,
        valueB,
    ],
    fieldName3: [
        {
            fieldNameX: value,
            fieldNameY: {...}
        },
        {
            fieldNameX: value,
            fieldNameY: {...}
        },
    ],
    fieldName4: { <= fieldInfo -> fieldInfo.fields: (static fields) + dynamic
        fieldNameX: value,
        fieldNameY: {}
        _e: { // dynamic fields (only for primitive type primitiveFieldInfo.type='obj' && fieldInfo._isBlock)
            f1: {...type1}, f2: {...type2}, ...
        }
        _o: [ //display order
            f1, fieldNameX, f2, fieldNameY
        ]
    },

    _info {
        fields: [ static + dynamic
            {name: fieldName1, type: fieldType1, ...conf}
            {name: fieldName2, type: fieldType2, items: itemsType1}
            {name: fieldName3, type: fieldType3, items: itemsType2}
            {name: fieldName4, type: fieldType4}, => fieldType4 => aliasType1 => type.fields = [{...}, ...]
        ]
    }
    _o: [...fieldDisplayOrder]
    _e: { // dynamic fields + static conf
        fieldName1: {...custom conf},
        f1: {...type1}, f2: {...type2}, ...
        fieldName3: {
            ...customConf,
            _items: {
                i: {...customConf}
            }
        },
    },
}
*/
const isNumber = val => /^\d+$/.test(val)
const _fieldInfoRecursive = (parent, parentFieldInfo, path, pathIndex) => {
    //console.log(parent, parentFieldInfo, path, pathIndex)
    switch (parentFieldInfo.type) {
        case "list":
            if (isNumber(path[pathIndex])) {
                const listIndex = parseInt(path[pathIndex], 10)
                const item = parent ? parent[listIndex] : null
                const itemFieldInfo = buildFieldInfo(
                    {
                        type: parentFieldInfo.items,
                        ...(parentFieldInfo._items && parentFieldInfo._items[listIndex]
                            ? parentFieldInfo._items[listIndex]
                            : []),
                    },
                    item
                )
                if (pathIndex === path.length - 1) return itemFieldInfo
                return _fieldInfoRecursive(item, itemFieldInfo, path, pathIndex + 1)
            } else {
                const itemsFieldInfo = buildFieldInfo({ type: parentFieldInfo.items })
                const item = parent ? parent[0] : null
                return _fieldInfoRecursive(item, itemsFieldInfo, path, pathIndex + 1)
            }
        case "obj": {
            const fields = parentFieldInfo.fields.filter(f => f.name === path[pathIndex])
            if (fields.length === 0) return null
            const child = parent ? parent[path[pathIndex]] : null
            const fieldInfo = buildFieldInfo(fields[0], child)
            if (pathIndex === path.length - 1) return fieldInfo
            return _fieldInfoRecursive(child, fieldInfo, path, pathIndex + 1)
        }
        default:
            return null
    }
}

const _fieldInfo = (entity, fieldName) => {
    if (!entity || !fieldName) return null
    const path = fieldName.split(".")

    const fields = entity._info.fields.filter(f => f.name === path[0])
    if (fields.length === 0) return null
    if (path.length === 1) return fields[0]
    return _fieldInfoRecursive(entity[path[0]], fields[0], path, 1)
}

const _childFieldInfo = (parent, parentFieldInfo, fieldName) =>
    _fieldInfoRecursive(parent, parentFieldInfo, fieldName.split("."), 0)

const _unwrap = entity => {
    //const _unwrap = entity => {
    const {
        _info,
        fieldInfo,
        childFieldInfo,
        unwrap,
        setValue,
        setChildValue,
        getValue,
        getChildValue,
        //getConf,
        //getChildConf,
        //setConf,
        ...orig
    } = entity
    return orig
}
const _getValueRec = (entity, path, pathIndex, fieldInfo, language) => {
    //const fieldInfo = baseEntity.fieldInfo(path.slice(0, pathIndex + 1).join("."))
    //console.log("GET REC", entity, path, pathIndex, fieldInfo, language)
    if (!entity || !fieldInfo) return null
    if (pathIndex === path.length - 1) {
        if (
            language &&
            fieldInfo._t !== false &&
            entity &&
            entity._i18n &&
            entity._i18n[language] &&
            entity._i18n[language][path[pathIndex]]
        ) {
            //const childFieldInfo = _childFieldInfo(entity, fieldInfo, path[pathIndex])

            //console.log(childFieldInfo)
            if (fieldInfo.type === "obj" || fieldInfo.type === "list")
                return entity[path[pathIndex]]
            return entity._i18n[language][path[pathIndex]]
        }
        return entity[path[pathIndex]]
    }

    switch (fieldInfo.type) {
        case "list":
            if (pathIndex === path.length - 2)
                return entity[path[pathIndex]][parseInt(path[pathIndex + 1], 10)]
            return _getValueRec(
                entity[path[pathIndex]][parseInt(path[pathIndex + 1], 10)],
                path,
                pathIndex + 2,
                _childFieldInfo(
                    entity[path[pathIndex]],
                    fieldInfo,
                    `${path[pathIndex + 1]}.${path[pathIndex + 2]}`
                ),
                language
            )
        case "obj":
            /*if (
                language &&
                fieldInfo._t !== false &&
                entity &&
                entity._i18n &&
                entity._i18n[language] &&
                entity._i18n[language][path[pathIndex]]
            )
                return _getValueRec(
                    entity._i18n[language][path[pathIndex]],
                    path,
                    pathIndex + 1,
                    _childFieldInfo(entity[path[pathIndex]], fieldInfo, path[pathIndex + 1]),
                    language
                )*/
            return _getValueRec(
                entity[path[pathIndex]],
                path,
                pathIndex + 1,
                _childFieldInfo(entity[path[pathIndex]], fieldInfo, path[pathIndex + 1]),
                language
            )

        default:
            return null
    }
}

const _getValue = (entity, field, language) => {
    if (!entity || !field) return null
    const path = field.split(".")
    const defaultLanguage = LANGUAGES
        ? entity
            ? entity._lang || LANGUAGES[0]
            : LANGUAGES[0]
        : null
    const lang = LANGUAGES ? (language !== defaultLanguage ? language : null) : null
    const fieldInfo = entity.fieldInfo(path[0])
    //if (!fieldInfo) return null

    //console.log("GET", field, lang)
    return _getValueRec(entity, path, 0, fieldInfo, lang)
}

const _getChildValue = (entity, parent, parentFieldInfo, field, language) => {
    const path = field.split(".")
    const defaultLanguage = LANGUAGES
        ? entity
            ? entity._lang || LANGUAGES[0]
            : LANGUAGES[0]
        : null
    const lang = LANGUAGES ? (language !== defaultLanguage ? language : null) : null
    //console.log("GET", field, lang)
    const fieldInfo = _childFieldInfo(parent, parentFieldInfo, path[0])
    return _getValueRec(parent, path, 0, fieldInfo, lang)
}

const _setValueRec = (parent, parentInfo, path, pathIndex, fieldInfo, value, language) => {
    //console.log("setValue", baseEntity, entity, path, pathIndex, value)
    //const fieldInfo = baseEntity.fieldInfo(path.slice(0, pathIndex + 1).join("."))
    if (!fieldInfo) return null
    if (pathIndex === path.length - 1) {
        if (
            language &&
            fieldInfo._t !== false &&
            parent &&
            fieldInfo.type !== "obj" &&
            fieldInfo.type !== "list"
        ) {
            if (!parent._i18n)
                return Object.assign({}, parent, {
                    _i18n: { [language]: { [path[pathIndex]]: value } },
                })
            if (!parent._i18n[language])
                return Object.assign({}, parent, {
                    _i18n: { ...parent._i18n, [language]: { [path[pathIndex]]: value } },
                })
            return Object.assign({}, parent, {
                _i18n: {
                    ...parent._i18n,
                    [language]: { ...parent._i18n[language], [path[pathIndex]]: value },
                },
            })
        }

        const p = Object.assign({}, parent, { [path[pathIndex]]: value })
        if (p._info) {
            if (p._info.conditional)
                return p._info.conditional.reduce((p, c) => (c[0](p) ? c[1](p) : p), p)
        } else {
            if (parentInfo && parentInfo.conditional)
                return parentInfo.conditional.reduce((p, c) => (c[0](p) ? c[1](p) : p), p)
        }
        return p
    }

    //console.log("setValue", baseEntity, entity, path, pathIndex, value, aliasInfo)

    switch (fieldInfo.type) {
        case "list": {
            if (!isNumber(path[pathIndex + 1])) return null
            const itemIndex = parseInt(path[pathIndex + 1], 10)
            const arrayVal = parent[path[pathIndex]]
            if (pathIndex === path.length - 2) {
                const p = Object.assign({}, parent, {
                    [path[pathIndex]]: [
                        ...arrayVal.slice(0, itemIndex),
                        value,
                        ...arrayVal.slice(itemIndex + 1, arrayVal.length - itemIndex - 1),
                    ],
                })
                if (fieldInfo.conditional)
                    return fieldInfo.conditional.reduce((p, c) => (c[0](p) ? c[1](p) : p), p)
                return p
            }
            return Object.assign({}, parent, {
                [path[pathIndex]]: [
                    ...arrayVal.slice(0, itemIndex),
                    _setValueRec(
                        arrayVal[itemIndex],
                        fieldInfo,
                        path,
                        pathIndex + 2,
                        _childFieldInfo(
                            parent[path[pathIndex]],
                            fieldInfo,
                            `${path[pathIndex + 1]}.${path[pathIndex + 2]}`
                        ),
                        value,
                        language
                    ),
                    ...arrayVal.slice(itemIndex + 1, arrayVal.length - itemIndex - 1),
                ],
            })
        }
        case "obj":
            return Object.assign({}, parent, {
                [path[pathIndex]]: _setValueRec(
                    parent[path[pathIndex]],
                    fieldInfo,
                    path,
                    pathIndex + 1,
                    _childFieldInfo(parent[path[pathIndex]], fieldInfo, path[pathIndex + 1]),
                    value,
                    language
                ),
            })

        default:
            return null
    }
}

const _setValue = (entity, field, value, language) => {
    //console.log("setValue", entity, field, value)
    if (!entity) throw new Error("Entity is null")
    if (!field) throw new Error("Field is null")
    const path = field.split(".")

    const defaultLanguage = LANGUAGES
        ? entity
            ? entity._lang || LANGUAGES[0]
            : LANGUAGES[0]
        : null
    const lang = LANGUAGES ? (language !== defaultLanguage ? language : null) : null
    const fieldInfo = entity.fieldInfo(path[0])
    //if (path.length === 1) return Object.assign({}, entity, { [field]: value })
    //const fieldInfo = entity._info.fieldInfo(path[0])

    return _setValueRec(entity, null, path, 0, fieldInfo, value, lang)
    //if (e._info.conditional) return e._info.conditional.reduce((e, c) => c(e), e)
}

const _setChildValue = (entity, parent, parentInfo, field, value, language) => {
    //console.log(entity, parent, parentInfo, field, value, language)
    const path = field.split(".")
    const defaultLanguage = LANGUAGES
        ? entity
            ? entity._lang || LANGUAGES[0]
            : LANGUAGES[0]
        : null
    const lang = LANGUAGES ? (language !== defaultLanguage ? language : null) : null
    const fieldInfo = entity.childFieldInfo(parent, parentInfo, path[0])
    return _setValueRec(parent, parentInfo, path, 0, fieldInfo, value, lang)
}

const _setSettingsRec = (parent, parentInfo, path, pathIndex, settings) => {
    //console.log(parent, parentInfo, path, pathIndex, settings)
    if (pathIndex === path.length - 1) {
        //if (!parent._e) parent._e = {}
        return {
            ...parent,
            _e: {
                ...parent._e,
                [path[pathIndex]]: {
                    ...(parent._e && parent._e[path[pathIndex]] ? parent._e[path[pathIndex]] : {}),
                    ...settings,
                },
            },
        }
    }
    const fieldInfo = parentInfo
        ? _childFieldInfo(parent, parentInfo, path[pathIndex])
        : parent.fieldInfo(path[pathIndex])
    switch (fieldInfo.type) {
        case "list": {
            if (!isNumber(path[pathIndex + 1])) return null
            const itemIndex = parseInt(path[pathIndex + 1], 10)
            const arrayVal = parent[path[pathIndex]]
            if (pathIndex === path.length - 2) {
                return {
                    ...parent,
                    _e: {
                        ...parent._e,
                        [fieldInfo.name]: {
                            ...(parent._e && parent._e[fieldInfo.name]
                                ? parent._e[fieldInfo.name]
                                : {}),
                            _items: {
                                ...(parent._e &&
                                parent._e[fieldInfo.name] &&
                                parent._e[fieldInfo.name]._items
                                    ? parent._e[fieldInfo.name]._items
                                    : {}),
                                itemIndex: {
                                    ...(parent._e &&
                                    parent._e[fieldInfo.name] &&
                                    parent._e[fieldInfo.name]._items &&
                                    parent._e[fieldInfo.name]._items[itemIndex]
                                        ? parent._e[fieldInfo.name]._items[itemIndex]
                                        : {}),
                                    ...settings,
                                },
                            },
                        },
                    },
                }
            }
            return {
                ...parent,
                [path[pathIndex]]: [
                    ...arrayVal.slice(0, itemIndex),
                    _setSettingsRec(
                        arrayVal[itemIndex],
                        _childFieldInfo(parent[path[pathIndex]], fieldInfo, path[pathIndex + 1]),
                        path,
                        pathIndex + 2,

                        settings
                    ),
                    ...arrayVal.slice(itemIndex + 1, arrayVal.length - itemIndex - 1),
                ],
            }
        }
        case "obj":
            return {
                ...parent,
                [path[pathIndex]]: _setSettingsRec(
                    parent[path[pathIndex]],
                    fieldInfo,
                    path,
                    pathIndex + 1,
                    settings
                ),
            }
        default:
            return null
    }
}
const _setSettings = (entity, field, settings) => {
    const path = field.split(".")

    //const fInfo = entity.fieldInfo(path[0])
    return _setSettingsRec(entity, null, path, 0, settings)
}

/*
const _getConfRec = (parent, parentFieldInfo, path, pathIndex) => {
    if (!parent || !parentFieldInfo) return {}

    switch (parentFieldInfo.type) {
        case "list":
            if (pathIndex === path.length - 1)
                return parent._c && parent._c._items
                    ? parent._c._items[parseInt(path[pathIndex], 10)]
                    : null
            return _getConfRec(
                parent[parseInt(path[pathIndex], 10)],
                _childFieldInfo(parent, parentFieldInfo, path[pathIndex]),
                path,
                pathIndex + 1
            )
        case "obj":
            if (pathIndex === path.length - 1) return parent._c ? parent._c[path[pathIndex]] : {}
            return _getConfRec(
                parent[path[pathIndex]],
                _childFieldInfo(parent, parentFieldInfo, path[pathIndex]),
                path,
                pathIndex + 1
            )
        default:
            if (pathIndex === path.length - 1) return parent._c ? parent._c[path[pathIndex]] : {}
            return null
    }
}

const _getConf = (entity, field) => {
    if (!entity || !field) return {}
    const path = field.split(".")
    if (path.length === 1) return entity._c ? entity._c[path[0]] : null
    const fieldInfo = entity.fieldInfo(path[0])
    if (fieldInfo.type === "list" && path.length === 2) {
        return entity && entity._c && entity._c._items
            ? entity._c._items[parseInt(path[1], 10)]
            : null
    }
    return _getConfRec(entity[path[0]], fieldInfo, path, 1)
}
const _getChildConf = (parent, parentFieldInfo, field) =>
    _getConfRec(parent, parentFieldInfo, field.split("."), 0)

const _setConfRec = (parent, parentFieldInfo, path, pathIndex, conf) => {
    if (!parent || !parentFieldInfo) return

    switch (parentFieldInfo.type) {
        case "list":
            if (pathIndex === path.length - 1) {
                if (!parent._c) parent._c = {}
                if (!parent._c._items) parent._c._items = {}
                parent._c._items[parseInt(path[pathIndex], 10)] = conf
                return
            }
            return _setConfRec(
                parent[parseInt(path[pathIndex], 10)],
                _childFieldInfo(parent, parentFieldInfo, path[pathIndex]),
                path,
                pathIndex + 1,
                conf
            )

        case "obj":
            if (pathIndex === path.length - 1) {
                if (!parent._c) parent._c = {}
                parent._c[path[pathIndex]] = conf
                return
            }
            return _setConfRec(
                parent[path[pathIndex]],
                _childFieldInfo(parent, parentFieldInfo, path[pathIndex]),
                path,
                pathIndex + 1,
                conf
            )
        default:
            if (pathIndex === path.length - 1) {
                if (!parent._c) parent._c = {}
                return parent._c ? parent._c[path[pathIndex]] : {}
            }
    }
}

const _setConf = (entity, field, conf) => {
    const path = field.split(".")
    if (path.length === 1) {
        if (!entity._c) entity._c = {}
        entity._c[path[0]] = conf
        return
    }
    const fieldInfo = entity.fieldInfo(path[0])
    if (fieldInfo.type === "list" && path.length === 2) {
        if (!entity._c) entity._c = {}
        if (!entity._c._items) entity._c._items = {}
        entity._c._items[parseInt(path[1], 10)] = conf
        return
    }
    return _setConfRec(entity[path[0]], fieldInfo, path, 1, conf)
}
*/
const wrapInfo = origEntity => {
    if (!origEntity) return origEntity
    //if (!origEntity || origEntity._info) return origEntity
    //console.log("wrapInfo", origEntity)
    const _info = {}
    const entity = { ...origEntity, _info }
    //console.log(entity)

    _info.collection =
        entity.type === "node"
            ? "node"
            : entityTypes[entity.type] && entityTypes[entity.type].collection
            ? entityTypes[entity.type].collection
            : entity.type

    _info.fields = (entity.type === "node"
        ? [...(entityFields[entity.bundle] || [])].map(field =>
              buildFieldInfo(field, entity[field.name])
          )
        : [...(entityFields[entity.type] || [])].map(field =>
              buildFieldInfo(field, entity[field.name])
          )
    ).filter(f => !!f)
    _info.defaultFields = _info.fields.map(f => f.name)
    if (entity.type === "node")
        _info.fields.push(
            buildFieldInfo({ name: "_seo", type: "seo", label: "SEO", region: "SEO" })
        )

    if (entity._e) {
        Object.keys(entity._e).forEach(name => {
            _info.fields.push(buildFieldInfo(entity._e[name], entity[name]))
        })
    }
    //_info.fields = _info.fields.filter(f => !!f)
    //console.log(_info)
    /*
    if (entity._e) {
        entity._e.forEach(field => {
            let found = false
            _info.fields = _info.fields.map(f => {
                if (f.name === field.name) {
                    found = true
                    return Object.assign({}, f, field)
                }
                return f
            })
            if (!found) _info.fields.push(field)
        })
    }

    _info.fields.forEach(
        (f, i) =>
            {_info.fields[i] = Object.assign({}, fieldTypes[f.type] ? fieldTypes[f.type] : {}, f)}
    )
    */
    _info.typeInfo = entity.type === "node" ? nodeBundles[entity.bundle] : entityTypes[entity.type]
    if (_info.typeInfo)
        Object.keys(_info.typeInfo).forEach(key => {
            if (key === "fields") {
                Object.keys(_info.typeInfo.fields).forEach(
                    fieldName =>
                        (_info.fields = _info.fields.map(field =>
                            field.name === fieldName
                                ? Object.assign({}, field, _info.typeInfo.fields[fieldName])
                                : field
                        ))
                )
            } else {
                if (!_info[key]) _info[key] = _info.typeInfo[key]
            }
        })

    entity.fieldInfo = function(fieldName) {
        return _fieldInfo(this, fieldName)
    }
    entity.childFieldInfo = _childFieldInfo
    /*entity.getConf = function(fieldName) {
        return _getConf(this, fieldName)
    }
    entity.getChildConf = _getChildConf
    entity.setConf = function(fieldName, conf) {
        return _setConf(this, fieldName, conf)
    }*/
    entity.unwrap = function() {
        return _unwrap(this)
    }
    entity.getValue = function(field, language) {
        return _getValue(this, field, language)
    }
    entity.getChildValue = function(parent, parentFieldInfo, field, language) {
        return _getChildValue(this, parent, parentFieldInfo, field, language)
    }
    entity.setValue = function(field, value, language) {
        return _setValue(this, field, value, language)
    }
    entity.setChildValue = function(parent, parentFieldInfo, field, value, language) {
        return _setChildValue(this, parent, parentFieldInfo, field, value, language)
    }
    entity.setSettings = function(field, fieldInfo, settings) {
        return _setSettings(this, field, fieldInfo, settings)
    }
    /*entity.presave = function() {
        return _presave(this)
    }*/
    return entity
}
export default wrapInfo
