import React, { useState } from "react"
import { Field, Modal } from "../../../ui"
import Gallery from "./Gallery"

const Image = ({ domRef, info, value, ...props }) => {
    const [open, setOpen] = useState()
    //const bgRef = useRef()

    // useFixed(value && value.bg, bgRef, domRef)
    const handleOpen = () => {
        setOpen(true)
    }
    const handleClose = () => {
        setOpen(false)
    }
    if (!value || !value.img || value.img.length === 0) return null

    //
    //<div ref={bgRef} className="bg">
    return (
        <div ref={domRef} {...props}>
            <Field info={info} field="bg" bg />
            <Field info={info} field="text" />
            <div className="content">
                <div className="gallery-container">
                    <div className="gallery mode-gallery">
                        <div className="gallery-wrap">
                            <Field
                                info={info}
                                field="img"
                                imageStyle="gallery-th"
                                className="gallery-gallery"
                                onClick={handleOpen}
                                wrap
                            />
                        </div>
                    </div>
                </div>
            </div>
            {open && (
                <Modal name="gallery" type="full" onClose={handleClose}>
                    <Gallery info={info} value={value} />
                </Modal>
            )}
        </div>
    )
}
export default Image
