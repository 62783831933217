import Welcome from "./home/Welcome"
import Block from "./Block"
import Card from "./Card"
import Icon from "./Icon"
import Hero from "./Hero"
import Department from "./contact/Department"
import Subdepartment from "./contact/Subdepartment"
import Contact from "./contact/Contact"
import FaIcon from "./FaIcon"
import Action from "./Action"
import Link from "./Link"
import LinkRenderer from "./LinkRenderer"
import Teaser from "./home/Teaser"
import FlotaNumbers from "./flota/FlotaNumbers"
import View from "./view/View"
import Image from "./gallery/Image"
import RecoveryTruckWelcome from "./recovery/RecoveryTruckWelcome"
import RecoveryDescription2 from "./recovery/RecoveryDescription2"
import RecoveryServices from "./recovery/RecoveryServices"
import RecoveryCall from "./recovery/RecoveryCall"

const fieldRenderer = {
    Welcome,
    Block,
    Card,
    Icon,
    Hero,
    Department,
    Subdepartment,
    Contact,
    FaIcon,
    Action,
    Link,
    LinkRenderer,
    Teaser,
    FlotaNumbers,
    View,
    Image,
    RecoveryTruckWelcome,
    RecoveryDescription2,
    RecoveryServices,
    RecoveryCall,
}
export default fieldRenderer
