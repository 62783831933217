import React, { useRef } from "react"
import { Field } from "../../ui"
import { useScreen, useScroll } from "lib"

const Hero = ({ domRef, info, value, className, children, ...props }) => {
    const titlePos = useRef()
    const screen = useScreen()
    const updateStyle = React.useCallback(
        scroll => {
            if (!titlePos.current) {
                const rect = domRef.current.querySelector(".hero-title").getBoundingClientRect()
                titlePos.current = screen.H - (rect.top + rect.height)
                domRef.current.style.setProperty("--title-h", titlePos.current)
                domRef.current.style.setProperty("--screen-h", screen.H)
            }
            if (scroll < screen.H) domRef.current.style.setProperty("--scroll", scroll)
        },
        [domRef, screen]
    )
    React.useEffect(() => {
        updateStyle(window.scrollY)
    }, [updateStyle])
    useScroll(updateStyle)
    //console.log(info, value)
    return (
        <div ref={domRef} className={`${className} ${value && value.size}`} {...props}>
            <Field className="bg" info={info} field="img" bg _nowrap />
            <div className="hero-title-wrap">
                <div className="hero-title">
                    <div className="hero-title-in">
                        <Field info={info} field="h1" tag="h2" />
                        <Field info={info} field="h2" tag="h1" />
                        <Field info={info} field="h3" tag="h3" />
                        <Field info={info} field="text" />
                    </div>
                    <div className="spacer" />
                </div>
                {children}
            </div>
        </div>
    )
}
export default Hero
