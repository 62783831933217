import React, { useRef, useLayoutEffect } from "react"
import { LazyImage } from "../../ui"
import { useScreen } from "../../lib"
//import { useFixed } from "./lib"
/*
export const useFixed = (fixed, ref, blockRef, screen) => {
    const unmounted = useUnmounted()
    const top = useRef(0)
    const anim = useRef()
    const lastPos = useRef(0)
    const visible = useRef()
    const status = useNode(fixed ? "status" : null)

    const doScroll = useCallback(() => {
        if (unmounted.current || !fixed) return
        anim.current = window.requestAnimationFrame(doScroll)
        if (!visible.current) return
        const scroll = window.scrollY

        if (scroll === lastPos.current) return
        lastPos.current = scroll
        ref.current.style.transform = `translate3d(0, ${scroll - top.current}px, 0)`
    }, [])
    useEffect(() => {
        if (fixed) {
            const rect = ref.current.getBoundingClientRect()
            top.current = rect.top + window.scrollY
            anim.current = window.requestAnimationFrame(doScroll)
        }
        return () => {
            window.cancelAnimationFrame(anim.current)
        }
    }, [fixed, screen, status])
    const onIntersect = useCallback(
        entry => {
            if (unmounted.current || visible.current === entry.isIntersecting) return
            visible.current = entry.isIntersecting
        },
        [fixed]
    )
    useIntersection(fixed ? blockRef : null, onIntersect)
}
*/
const O = {
    landscape: "l",
    portrait: "p",
}
const BlockRenderer = props => {
    const { domRef, info, value, id, className, style, ...other } = props

    const screen = useScreen()
    const bgRef = useRef()
    const blockInnerRef = useRef()
    useLayoutEffect(() => {
        if (
            value.bgProp &&
            value.bg &&
            value.bg.length > 0 &&
            value.bg[0].w > 0 &&
            value.bg[0].h > 0
        ) {
            let paddingTop = (value.bg[0].h / value.bg[0].w) * 100 + "%"

            if (value.bg[0].srcset) {
                const srcset = value.bg[0].srcset.filter(
                    item =>
                        (!item.mq || item.mq.length === 0 || item.mq.indexOf(screen.MQ) >= 0) &&
                        (!item.o || item.o.length === 0 || item.o.indexOf(O[screen.O]) >= 0) &&
                        (!item.r || item.r.length === 0 || item.r.indexOf(screen.RATIO) >= 0)
                )
                if (srcset.length > 0 && srcset[0].h > 0 && srcset[0].w > 0) {
                    paddingTop = (srcset[0].h / srcset[0].w) * 100 + "%"
                }
            }

            blockInnerRef.current.style.height = 0
            blockInnerRef.current.style.paddingTop = paddingTop
        }
    }, [value, screen])
    //useFixed(value && value.f, bgRef, domRef)
    if (!value) return <div ref={domRef} />
    //const { fieldInfo } = info

    let classes = className

    if (value.bgProp && value.bg && value.bg.length > 0 && value.bg[0].w > 0 && value.bg[0].h > 0) {
        //let paddingTop = (value.bg[0].h / value.bg[0].w) * 100 + "%"
        classes += " block-proportional"
        /*
        if (value.bg[0].srcset) {
            const srcset = value.bg[0].srcset.filter(
                item =>
                    (!item.mq || item.mq.length === 0 || item.mq.indexOf(screen.MQ) >= 0) &&
                    (!item.o || item.o.length === 0 || item.o.indexOf(O[screen.O]) >= 0) &&
                    (!item.r || item.r.length === 0 || item.r.indexOf(screen.RATIO) >= 0)
            )
            if (srcset.length > 0 && srcset[0].h > 0 && srcset[0].w > 0) {
                paddingTop = (srcset[0].h / srcset[0].w) * 100 + "%"
            }
        }

        styleBlock = {
            height: 0,
            paddingTop,
        }*/
    }
    if (value.f) {
        classes += " block-fullfixed"
    }
    if (value.h) classes += " horizontal"

    const blockStyle = { ...style }
    if (value.bgColor) blockStyle.background = value.bgColor

    return (
        <div ref={domRef} id={id} className={classes} style={blockStyle} {...other}>
            {value.bg &&
                value.bg.length > 0 &&
                value.bg.map((bg, i) => (
                    <LazyImage domRef={bgRef} key={i} className="bg" bg={true} src={bg} />
                ))}
            <div ref={blockInnerRef} className="block-inner">
                <div className="block-content">{props.children}</div>
            </div>
        </div>
    )
}
export default BlockRenderer
