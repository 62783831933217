import React, { useState, useCallback } from "react"
import { useQuery, useHistory } from "../../lib"
import { Link, FaIcon } from "../../ui"

const mainMenuQuery = {
    collection: "menu",
    query: {},
}

const MainMenu = ({ language }) => {
    const { location } = useHistory()
    const [mainMenu] = useQuery(mainMenuQuery, { single: true, tag: "mainMenu" })
    const [open, setOpen] = useState(false)
    const handleToggle = useCallback(e => {
        e.preventDefault()
        setOpen(open => !open)
    }, [])
    const handleActivate = useCallback(() => {
        setOpen(false)
        return true
    }, [])

    if (!mainMenu) return null
    const menu = mainMenu.items
        .map(item => item.filter(it => it.lang === language))
        .filter(item => item.length > 0)
        .map(item => item[0])

    return (
        <>
            <ul className={`main-menu ${open ? "open" : ""}`}>
                {menu.map((item, i) => (
                    <li key={i}>
                        <Link
                            to={item.p}
                            className={item.p === location.pathname ? "active" : ""}
                            onActivate={handleActivate}
                        >
                            {item.title}
                        </Link>
                    </li>
                ))}
            </ul>
            <button id="mobile-menu" onClick={handleToggle}>
                <FaIcon icon="faBars" size={24} />
            </button>
        </>
    )
}

export default React.memo(MainMenu)
