import React, { useMemo } from "react"
import { useQuery } from "../../../lib"
import { Link } from "../../../ui"
import JobRowRenderer from "./JobRowRenderer"
import NewsRowRenderer from "./NewsRowRenderer"

const rowRenderers = {
    jobRow: JobRowRenderer,
    newsRow: NewsRowRenderer,
}
const View = ({ domRef, info, value, className, ...props }) => {
    const [query, config] = useMemo(() => {
        //if (!value.query) return [null, null]
        const query = {}
        const config = { setEntityInfo: true }
        if (value.collection) query.collection = value.collection
        try {
            if (value.query) query.query = JSON.parse(value.query)
        } catch (e) {
            console.log(e)
        }
        if (value.projection && value.projection.trim() !== "") {
            try {
                query.projection = JSON.parse(value.projection)
                query.projection.type = 1
                query.projection.bundle = 1
            } catch (e) {
                console.log(e)
            }
        }
        if (value.sort && value.sort.trim() !== "") {
            try {
                query.sort = JSON.parse(value.sort)
            } catch (e) {
                console.log(e)
            }
        }
        if (value.pageSize && value.pageSize.trim() !== "")
            config.pageSize = parseInt(value.pageSize, 10)

        return [query, config]
    }, [value])
    const [rows] = useQuery(query, config)

    const RowRenderer = rowRenderers[value.rowRenderer]
    if (!RowRenderer) return null
    return (
        <div ref={domRef} className={`${className} ${value.view || ""}`} {...props}>
            <div className="view-content">
                {value.title && <h2 className="title">{value.title}</h2>}
                {rows &&
                    rows.map((row, i) => <RowRenderer key={i} info={info} row={row} index={i} />)}
            </div>
            {value.moreLink && (
                <Link to={value.moreLink} className="more-link">
                    {value.moreText || "Mai mult..."}
                </Link>
            )}
        </div>
    )
}
export default View
