import React from "react"

const FormFieldText = ({ domRef, fieldValue, dispatch, info, value, ...props }) => {
    const handleChange = e => {
        //console.log(info.fieldInfo, value)
        dispatch({
            type: "change",
            field: info.field,
            label: value.label,
            fieldType: value.fieldType,
            value: e.currentTarget.value,
        })
    }

    return (
        <div ref={domRef} {...props}>
            <input
                name={info.field}
                type="text"
                value={fieldValue || ""}
                placeholder={info.entity.getChildValue(
                    value,
                    info.fieldInfo,
                    "label",
                    info.language
                )}
                onChange={handleChange}
            />
        </div>
    )
}
export default FormFieldText
