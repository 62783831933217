import React from "react"
import { Field, FaIcon } from "../../../ui"

const Subdepartment = ({ domRef, info, value, ...props }) => (
    <div ref={domRef} {...props}>
        <Field info={info} field="name" tag="h3" />
        <Field info={info} field="head">
            <FaIcon icon="faUser" size={24} />
        </Field>
        <Field info={info} field="groupEmail" linkTo={`mailto:${value.groupEmail}`}>
            <FaIcon icon="faEnvelope" size={18} />
        </Field>
        <Field
            info={info}
            field="nrGeneral"
            linkTo={value.nrGeneral ? `tel:${value.nrGeneral.replace(/\D/g, "")}` : null}
        >
            <FaIcon icon="faPhone" size={18} />
        </Field>
        <Field info={info} field="email" linkTo={`mailto:${value.email}`}>
            <FaIcon icon="faEnvelope" size={18} />
        </Field>
        <Field
            info={info}
            field="mobile"
            linkTo={value.mobile ? `tel:${value.mobile.replace(/\D/g, "")}` : null}
        >
            <FaIcon icon="faMobile" size={18} />
        </Field>
        <Field info={info} field="obs" />
    </div>
)
export default Subdepartment
