import React from "react"
import { Field } from "../../../ui"

const Teaser = ({ domRef, info, value, children, ...props }) => (
    <div ref={domRef} {...props}>
        <div className="field-in">
            <Field info={info} field="thumb" />
            <Field info={info} field="link" _nowrap noadmin>
                <Field info={info} field="title" noadmin />
            </Field>
        </div>
    </div>
)

export default Teaser
