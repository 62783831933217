import React, { useRef, useState, useLayoutEffect } from "react"
import { useInterval, useScreen } from "../../../lib"
import { Field } from "../../../ui"

const useSlider = (domRef, n) => {
    const nSlides = useRef(1)
    const active = useRef(0)
    const prev = useRef(-1)
    if (n !== nSlides.current) nSlides.current = n || 1

    const first = useRef()
    useLayoutEffect(() => {
        if (first.current) return
        first.current = true
        const nodes = domRef.current.querySelectorAll(".field.slides .lazy")
        const activeNode = nodes.item(active.current)
        domRef.current.dataset.active = active.current
        if (activeNode) activeNode.classList.add("active")
    }, [domRef])
    useInterval(() => {
        requestAnimationFrame(() => {
            const nodes = domRef.current.querySelectorAll(".field.slides .lazy")

            if (prev.current >= 0) {
                const prevNode = nodes[prev.current]
                prevNode.classList.remove("exiting")
            }
            prev.current = active.current
            const prevNode = nodes.item(prev.current)
            if (prevNode) {
                prevNode.classList.remove("active")
                prevNode.classList.add("exiting")
            }

            active.current = (active.current + 1) % nSlides.current
            domRef.current.dataset.active = active.current
            const activeNode = nodes.item(active.current)
            if (activeNode) activeNode.classList.add("active")
        })
    }, 7000)
}
const useStyles = () => {
    const [first, setFirst] = useState(true)
    const screen = useScreen()
    useLayoutEffect(() => {
        if (first) setFirst(false)
    }, [first])
    const styles = {
        h1: {
            lineHeight: "1em",
            paddingBottom: "0.3em",
        },
        h2: {
            lineHeight: "1em",
            paddingBottom: "0.3em",
        },
        slogan1: {
            lineHeight: "1em",
            padding: "2.2em 3em 2.5em 3em",
            textAlign: "center",
            color: "#b9b9b9",
        },
        slogan2: {
            lineHeight: "1em",
            padding: "2.2em 3em 2.5em 3em",
            textAlign: "center",
            color: "#b9b9b9",
        },
    }
    switch (screen.O) {
        case "landscape":
            styles.slogan1.fontSize = `${screen.W / 25}px`
            styles.slogan1.right = "-1.5em"
            styles.slogan1.bottom = "-2em"
            styles.slogan2.fontSize = `${screen.W / 75}px`
            styles.slogan2.left = "2em"
            styles.slogan2.top = "5em"
            switch (screen.MQ) {
                case "lg":
                case "xl":
                    styles.h1.fontSize = `${screen.W / 17}px`
                    styles.h2.fontSize = `${screen.W / 40}px`
                    break
                case "md":
                    styles.h1.fontSize = `${screen.W / 17}px`
                    styles.h2.fontSize = `${screen.W / 40}px`
                    break
                case "sm":
                    styles.h1.fontSize = `${screen.W / 18}px`
                    styles.h2.fontSize = `${screen.W / 30}px`
                    break
                default:
                    styles.h1.fontSize = `${screen.W / 18}px`
                    styles.h2.fontSize = `${screen.W / 30}px`
            }
            break
        default: {
            styles.h1.fontSize = `${screen.W / 10}px`
            styles.h2.fontSize = `${screen.W / 20}px`
            switch (screen.MQ) {
                case "xl":
                case "lg":
                case "md":
                    styles.slogan1 = {
                        ...styles.slogan1,
                        fontSize: `${screen.W / 25}px`,
                        right: "-1.5em",
                        bottom: "-2em",
                    }
                    styles.slogan2 = {
                        ...styles.slogan2,
                        fontSize: `${screen.W / 75}px`,
                        left: "2em",
                        top: "5em",
                    }
                    break
                case "xs":
                    styles.slogan1 = {
                        ...styles.slogan1,
                        fontSize: `${screen.W / 15}px`,
                        right: "-1.5em",
                        bottom: "-2em",
                    }
                    styles.slogan2 = {
                        ...styles.slogan2,
                        fontSize: `${screen.W / 30}px`,
                        left: "-1.5em",
                        top: "1em",
                    }
                    break
                default:
                    styles.slogan1 = {
                        ...styles.slogan1,
                        fontSize: `${screen.W / 15}px`,
                        right: "-1.5em",
                        bottom: "-2em",
                    }
                    styles.slogan2 = {
                        ...styles.slogan2,
                        fontSize: `${screen.W / 30}px`,
                        left: "-1.5em",
                        top: "0.5em",
                    }
                    break
            }
        }
    }
    return styles
}

const RecoveryTruckWelcome = ({ domRef, info, value, ...props }) => {
    useSlider(domRef, value && value.slides ? value.slides.length : 0)
    const styles = useStyles()
    const slider1H1 = useRef()
    const slider1H2 = useRef()
    const slider2H1 = useRef()
    const slider2H2 = useRef()
    const slogan1 = useRef()
    const slogan2 = useRef()
    useLayoutEffect(() => {
        Object.keys(styles.h1).forEach(key => {
            slider1H1.current.style[key] = styles.h1[key]
            slider2H1.current.style[key] = styles.h1[key]
        })
        Object.keys(styles.h2).forEach(key => {
            slider1H2.current.style[key] = styles.h2[key]
            slider2H2.current.style[key] = styles.h2[key]
        })
        Object.keys(styles.slogan1).forEach(
            key => (slogan1.current.style[key] = styles.slogan1[key])
        )
        Object.keys(styles.slogan2).forEach(
            key => (slogan2.current.style[key] = styles.slogan2[key])
        )
    }, [styles])

    return (
        <div ref={domRef} {...props}>
            <Field info={info} field="bg" bg _nowrap />
            <div className="content">
                <div className="slider-h first">
                    <div ref={slider1H1} className="slider-h1">
                        RAVITEX <i>Recovery</i> TRUCK
                    </div>
                    <Field domRef={slider1H2} info={info} field="hero1" className="slider-h2" />
                </div>
                <div className="slider-h second">
                    <Field domRef={slider2H1} info={info} field="hero2" className="slider-h1" />
                    <div ref={slider2H2} className="slider-h2">
                        RAVITEX <i>Recovery</i> TRUCK
                    </div>
                </div>
                <Field info={info} field="slides" bg />
                <div ref={slogan1} className="logo-slogan-recuperam slider-arrow c-1 ">
                    <Field info={info} field="slogan" className="dafoe" />
                </div>
                <div ref={slogan2} className="logo-slogan-recuperam slider-arrow c-3 ">
                    <Field info={info} field="slogan" className="dafoe" />
                </div>
            </div>
        </div>
    )
}
export default RecoveryTruckWelcome
