import React from "react"
import { Helmet } from "react-helmet"
import { LANGUAGES, WEBSITE_NAME, BASE } from "../../config"
import { realPath } from "../../lib/util"
import { Delayed } from "../../ui"

const locales = {
    ro: "ro-RO",
    en: "en-US",
    de: "de-DE",
}
const SEO = ({ node, language }) => {
    const url = typeof window === "undefined" ? `${BASE}${global.location}` : window.location.href

    const title =
        WEBSITE_NAME + (node && node.title ? ` - ${node.getValue("title", language)}` : "")
    const seoTitle = node.getValue("_seo.title", language) || title

    const locale = locales[language] || "ro_RO"
    const description = node.getValue("_seo.description", language) || ""
    const thumb = node.getValue("_seo.image") || node.getValue("thumb")
    const image = thumb && thumb.length > 0 ? thumb[0].url : null
    let meta = [
        { name: "description", content: description },
        { name: "og:url", content: url },
        { name: "og:type", content: "article" },
        { name: "og:title", content: seoTitle },
        { name: "og:description", content: description },
        { name: "og:locale", content: locale },
        { name: "twitter:url", content: url },
        { name: "twitter:card", content: "summary" },
        { name: "twitter:title", content: title },
        { name: "twitter:description", content: description },
    ]
    //console.log(node)
    if (image)
        meta.push({
            name: "og:image",
            content: realPath(image, "thumb"),
        })
    const props = {}
    if (language) props.htmlAttributes = { lang: language }
    let link = []
    if (LANGUAGES && node && node.path) {
        node.path.forEach(p =>
            link.push({ rel: "alternate", href: `${BASE}${p.p}`, hrefLang: p.lang })
        )
        node.path
            .filter(path => path.lang === LANGUAGES[0])
            .map(p => link.push({ rel: "alternate", href: `${BASE}${p.p}`, hrefLang: "x-default" }))
        node.path
            .filter(path => path.lang === language)
            .map(p => link.push({ rel: "canonical", href: `${BASE}${p.p}` }))
    }
    props.link = link
    return (
        <Delayed key={url} maxDelay={5000}>
            <Helmet title={title} meta={meta} {...props} />
        </Delayed>
    )
}
export default SEO
/*                {LANGUAGES &&
                    node &&
                    node.path.map(p => (
                        <link
                            rel="alternate"
                            href={`${BASE}${p.p}`}
                            hrefLang={p.lang}
                            key={p.lang}
                        />
                    ))}
                {LANGUAGES &&
                    node &&
                    node.path
                        .filter(path => path.lang === LANGUAGES[0])
                        .map(p => (
                            <link
                                rel="alternate"
                                href={`${BASE}${p.p}`}
                                hrefLang="x-default"
                                key={`def-${p.lang}`}
                            />
                        ))}
                {LANGUAGES &&
                    node &&
                    node.path
                        .filter(path => path.lang === language)
                        .map(p => (
                            <link rel="canonical" href={`${BASE}${p.p}`} key={`can-${p.lang}`} />
                        ))}
            </Helmet>*/
