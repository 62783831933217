import React from "react"
import { Field } from "../../../ui"

const RecoveryDescription2 = ({ domRef, info, value, ...other }) => {
    return (
        <div ref={domRef} {...other}>
            <div className="block-in">
                <div className="block-header">
                    <Field info={info} field="title" tag="h2" />
                    <Field info={info} field="subtitle" tag="h3" />
                </div>
                <div className="block-content">
                    <Field info={info} field="bg" />
                    <Field info={info} field="text" />
                    <Field info={info} field="text2" />
                </div>
            </div>
        </div>
    )
}
export default RecoveryDescription2
