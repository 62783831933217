import { faArrowsAlt } from "@fortawesome/free-solid-svg-icons/faArrowsAlt"
import { faWindowMaximize } from "@fortawesome/free-solid-svg-icons/faWindowMaximize"
import { faWindowMinimize } from "@fortawesome/free-solid-svg-icons/faWindowMinimize"
import { faWindowRestore } from "@fortawesome/free-solid-svg-icons/faWindowRestore"
import { faLock } from "@fortawesome/free-solid-svg-icons/faLock"
import { faLockOpen } from "@fortawesome/free-solid-svg-icons/faLockOpen"
import { faUsers } from "@fortawesome/free-solid-svg-icons/faUsers"

import { faArrowLeft } from "@fortawesome/free-solid-svg-icons/faArrowLeft"
import { faArrowRight } from "@fortawesome/free-solid-svg-icons/faArrowRight"
import { faQuoteLeft } from "@fortawesome/free-solid-svg-icons/faQuoteLeft"
import { faQuoteRight } from "@fortawesome/free-solid-svg-icons/faQuoteRight"
import { faFacebookF } from "@fortawesome/free-brands-svg-icons/faFacebookF"
import { faTwitter } from "@fortawesome/free-brands-svg-icons/faTwitter"
import { faInstagram } from "@fortawesome/free-brands-svg-icons/faInstagram"
import { faYoutube } from "@fortawesome/free-brands-svg-icons/faYoutube"
import { faAngleLeft } from "@fortawesome/free-solid-svg-icons/faAngleLeft"
import { faAngleRight } from "@fortawesome/free-solid-svg-icons/faAngleRight"
import { faArrowCircleRight } from "@fortawesome/free-solid-svg-icons/faArrowCircleRight"
import { faAngleDoubleLeft } from "@fortawesome/free-solid-svg-icons/faAngleDoubleLeft"
import { faAngleDoubleRight } from "@fortawesome/free-solid-svg-icons/faAngleDoubleRight"
import { faCalendarAlt } from "@fortawesome/free-solid-svg-icons/faCalendarAlt"
import { faList } from "@fortawesome/free-solid-svg-icons/faList"
import { faImages } from "@fortawesome/free-solid-svg-icons/faImages"

import { faDownload } from "@fortawesome/free-solid-svg-icons/faDownload"

import { faTrophy as trophy } from "@fortawesome/free-solid-svg-icons/faTrophy"
import { faCertificate as certificate } from "@fortawesome/free-solid-svg-icons/faCertificate"
import { faLeaf as leaf } from "@fortawesome/free-solid-svg-icons/faLeaf"
import { faTruck as truck } from "@fortawesome/free-solid-svg-icons/faTruck"
import { faBus as bus } from "@fortawesome/free-solid-svg-icons/faBus"
import { faCogs as cogs } from "@fortawesome/free-solid-svg-icons/faCogs"
import { faSnowflake } from "@fortawesome/free-solid-svg-icons/faSnowflake"
import { faCubes as cubes } from "@fortawesome/free-solid-svg-icons/faCubes"
import { faFire as fire } from "@fortawesome/free-solid-svg-icons/faFire"
import { faClock } from "@fortawesome/free-solid-svg-icons/faClock"
import { faPhoneSquare } from "@fortawesome/free-solid-svg-icons/faPhoneSquare"
import { faPuzzlePiece } from "@fortawesome/free-solid-svg-icons/faPuzzlePiece"
import { faPlusSquare } from "@fortawesome/free-solid-svg-icons/faPlusSquare"
import { faMinusSquare } from "@fortawesome/free-solid-svg-icons/faMinusSquare"
import { faUserCircle } from "@fortawesome/free-solid-svg-icons/faUserCircle"
import { faEnvelope } from "@fortawesome/free-solid-svg-icons/faEnvelope"

import { faThumbsUp } from "@fortawesome/free-solid-svg-icons/faThumbsUp"
import { faUser } from "@fortawesome/free-solid-svg-icons/faUser"
import { faMobile } from "@fortawesome/free-solid-svg-icons/faMobile"
import { faMobileAlt } from "@fortawesome/free-solid-svg-icons/faMobileAlt"
import { faPhone } from "@fortawesome/free-solid-svg-icons/faPhone"
import { faFax } from "@fortawesome/free-solid-svg-icons/faFax"
import { faMapMarker } from "@fortawesome/free-solid-svg-icons/faMapMarker"
import { faHandshake } from "@fortawesome/free-solid-svg-icons/faHandshake"
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons/faCheckCircle"

const icons = {
    faArrowsAlt,
    faWindowMaximize,
    faWindowMinimize,
    faWindowRestore,
    faLock,
    faLockOpen,
    faUsers,
    faArrowLeft,
    faArrowRight,
    faAngleLeft,
    faAngleRight,
    faArrowCircleRight,
    faAngleDoubleLeft,
    faAngleDoubleRight,
    faQuoteLeft,
    faQuoteRight,
    faFacebookF,
    faTwitter,
    faInstagram,
    faYoutube,
    faCalendarAlt,
    faList,
    faImages,
    faDownload,
    trophy,
    certificate,
    leaf,
    truck,
    bus,
    cogs,
    "snowflake-o": faSnowflake,
    cubes,
    fire,
    "clock-o": faClock,
    "phone-square": faPhoneSquare,
    "puzzle-piece": faPuzzlePiece,
    faPlusSquare,
    faMinusSquare,
    faUserCircle,
    faEnvelope,

    faThumbsUp,
    faUser,
    faMobile,
    faMobileAlt,
    faPhone,
    faFax,
    faMapMarker,
    faHandshake,
    faCheckCircle,
}
export default icons
