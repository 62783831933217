import React, { useRef, useState, useLayoutEffect, useCallback } from "react"
import { addLocationListener, removeLocationListener, getLocation } from "../lib/history"
import { useScreen } from "../lib"
import NodeManager from "./NodeManager"

const Location = () => {
    const [location, setLocation] = useState(getLocation())

    const handleLocationChange = useCallback((pathname, search, language) => {
        setLocation({ pathname, search, language })
    }, [])

    const screenClasses = useRef()
    useScreen((w, h, mq, o) => {
        if (typeof window === "undefined") return
        const newScreenClasses = `${mq} ${o} ${w < 400 || h < 400 ? "mobile" : ""}`
        if (screenClasses.current === newScreenClasses) return
        screenClasses.current = newScreenClasses
        document.body.className = screenClasses.current
    })

    useLayoutEffect(() => {
        addLocationListener(handleLocationChange)
        return () => removeLocationListener(handleLocationChange)
    }, [handleLocationChange])
    return (
        <NodeManager
            pathname={location.pathname}
            search={location.search}
            language={location.language}
        />
    )
}
export default Location
