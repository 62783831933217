import React from "react"
import { Field } from "../../ui"

const LinkRenderer = ({ info }) => (
    <Field info={info} field="path" _editParent>
        <Field info={info} field="label" noadmin />
    </Field>
)

export default React.memo(LinkRenderer)
