import React from "react"
//import { Field } from "../../ui"

const Contact = ({ domRef, info, value, ...props }) => {
    //console.log(value, info)
    return (
        <div ref={domRef} {...props}>
            contact
        </div>
    )
}
export default Contact
