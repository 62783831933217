const entityFields = {
    NotFound: [
        { name: "title", type: "text", label: "Titlu", tag: "h1", region: "title" },
        { name: "body", type: "html", label: "Conținut" },
    ],
    home: [{ name: "title", type: "text", label: "Titlu", tag: "h1", region: "title" }],
    page: [{ name: "title", type: "text", label: "Titlu", tag: "h1", region: "title" }],
    article: [
        { name: "header", type: "img", label: "Antet", region: "title" },
        { name: "title", type: "text", label: "Titlu", tag: "h1" },
        { name: "date", type: "date", label: "Data" },
        { name: "body", type: "html", label: "Conținut" },
        { name: "gallery", type: "img", label: "Galerie" },
        { name: "summary", type: "html", label: "Sumar", region: "teaser" },
        { name: "thumb", type: "img", label: "Miniatură", single: true, region: "teaser" },
    ],
    job: [
        { name: "title", type: "text", label: "Titlu", t: true },
        { name: "subtitle", type: "text", label: "Subtitlu", t: true },
        { name: "descr", type: "html", label: "Descriere", t: true },
        { name: "mtitle", type: "text", label: "Titlu detalii", t: true },
        { name: "mission", type: "html", label: "Detalii", t: true },
        { name: "text", type: "html", label: "Text", t: true },
        { name: "footer", type: "html", label: "Încheiere", t: true },
    ],
    footer: [
        { name: "about", type: "html", label: "Despre noi", t: true },
        { name: "tel", type: "text", label: "Telefon", t: true },
        { name: "fax", type: "text", label: "Fax", t: true },
        { name: "mail", type: "text", label: "E-mail", t: true },
        { name: "paddr", type: "text", label: "Adresa postala", t: true },
        { name: "addr", type: "text", label: "Adresa", t: true },
        { name: "menu1label", type: "text", label: "Eticheta Meniu 1", t: true },
        { name: "menu1link", type: "href", label: "Legătură Meniu 1", t: true },
        { name: "menu2label", type: "text", label: "Eticheta Meniu 2", t: true },
        { name: "menu2link", type: "href", label: "Legătură Meniu 2", t: true },
        { name: "menu3label", type: "text", label: "Eticheta Meniu 3", t: true },
        { name: "menu3link", type: "href", label: "Legătură Meniu 3", t: true },
        { name: "menu4label", type: "text", label: "Eticheta Meniu 4", t: true },
        { name: "menu4link", type: "href", label: "Legătură Meniu 4", t: true },
    ],
}
export { entityFields }
