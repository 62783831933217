import bugsnag from "@bugsnag/js"
import bugsnagReact from "@bugsnag/plugin-react"
import React from "react"
import ReactDOM from "react-dom"
import LanguageManager from "./lib/LanguageManager"
import Auth from "./admin/Auth"
import Location from "./components/Location"

const bugsnagClient = bugsnag("0d785b2b6fbe73b1473e27510504e8b7")
bugsnagClient.use(bugsnagReact, React)
const ErrorBoundary = bugsnagClient.getPlugin("react")
if (window.initialStore) window.isHydrating = true
window.__MUI_USE_NEXT_TYPOGRAPHY_VARIANTS__ = true

ReactDOM.hydrate(
    <ErrorBoundary>
        <LanguageManager>
            <Auth>
                <Location />
            </Auth>
        </LanguageManager>
    </ErrorBoundary>,
    document.getElementById("root"),
    () => {
        window.isHydrating = false
        delete window.initialStore
    }
)
//registerServiceWorker();
//unregister()
