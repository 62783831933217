import React, { useRef, useCallback } from "react"
import { LazyImage, Field } from "../../../ui"
import { useUnmounted, useIntersection } from "../../../lib"
import { TweenMax } from "gsap"

const Counter = ({ n }) => {
    const unmounted = useUnmounted()
    const ref = useRef()
    const visible = useRef()

    const onIntersect = useCallback(
        entry => {
            if (unmounted.current || visible.current || !entry.isIntersecting) return
            visible.current = entry.isIntersecting
            const cVal = { val: 0 }
            TweenMax.to(cVal, 2, {
                val: n,
                roundProps: "val",
                onUpdate: () => {
                    if (unmounted.current) return
                    ref.current.innerHTML = cVal.val
                },
            })
        },
        [n, unmounted]
    )
    useIntersection(ref, onIntersect)
    return <div ref={ref} className="counter" />
}

const FlotaNumbers = ({ domRef, info, value, className, ...props }) => (
    <div ref={domRef} className={`${className} bg-prop`} {...props}>
        {value.bg &&
            value.bg.length > 0 &&
            value.bg.map((bg, i) => (
                <LazyImage key={i} className="bg" bg={true} src={bg} proportional />
            ))}
        <div className="content">
            <div className="content-in">
                <Field field="text" info={info} />
                <div className="counters">
                    <div className="fcounter">
                        <Counter n={parseInt(value.n1, 10)} />
                        <Field className="label" field="label1" info={info} />
                    </div>
                    <div className="fcounter">
                        <Counter n={parseInt(value.n2, 10)} />
                        <Field className="label" field="label2" info={info} />
                    </div>
                </div>
            </div>
        </div>
    </div>
)

export default FlotaNumbers
