import React from "react"
import { Field, FaIcon } from "../../../ui"

const Department = ({ domRef, info, value, children, ...props }) => (
    <div ref={domRef} {...props}>
        <Field info={info} field="name" tag="h3">
            <FaIcon icon="faHandshake" size={48} />
        </Field>
        <div className="sub-content">{children}</div>
    </div>
)
export default Department
