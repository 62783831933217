import React, { useState } from "react"
import { LazyImage, Field } from "../../../ui"
import { useInterval } from "../../../lib"

const BgSlideshow = ({ slides }) => {
    return (
        <div className="bg">
            <LazyImage bg={true} src={slides[0]} />
        </div>
    )
}
const Slogan = ({ info }) => {
    const [index, setIndex] = useState(0)
    useInterval(() => {
        setIndex(index => (index + 1) % 2)
    }, 5000)
    return (
        <div className="field-left">
            <Field info={info} field="slogan1" className={index === 0 ? "active" : ""} />
            <Field info={info} field="slogan2" className={index === 1 ? "active" : ""} />
        </div>
    )
}
const Welcome = ({ domRef, info, value, children, ...props }) => (
    <div ref={domRef} {...props}>
        {value.bg && <BgSlideshow slides={value.bg} />}
        <div className="field-right">
            <div className="field-in">
                <div className="wrap">{children}</div>
            </div>
        </div>
        <Slogan info={info} />
    </div>
)
export default Welcome
