import React, { useState } from "react"
import { LANGUAGES } from "../../config"
import { Link } from "../../ui"
import { realPath } from "../../lib/util"

const LanguageSelector = ({ node, language }) => {
    const [open, setOpen] = useState(false)
    const handleClick = () => {
        setOpen(open => !open)
        return true
    }

    return (
        <div className={"lang-selector" + (open ? " open" : "")}>
            <div className="lang-selector-in">
                <div className="lang-current" onClick={handleClick}>
                    <img src={realPath("/upload/" + language + ".png")} alt={language} /> {language}
                </div>
                <ul className="lang-dropdown">
                    {LANGUAGES.map((lang, i) => {
                        const path =
                            node && node.path
                                ? node.path.filter(item => item.lang === lang).map(item => item.p)
                                : []
                        return (
                            <li key={i}>
                                <Link
                                    to={path[0] || `/${lang}`}
                                    className={`lang ${lang === language ? "active" : ""}`}
                                    onActivate={handleClick}
                                    data-lang={lang}
                                >
                                    <img src={realPath("/upload/" + lang + ".png")} alt={lang} />{" "}
                                    {lang}
                                </Link>
                            </li>
                        )
                    })}
                </ul>
            </div>
        </div>
    )
}
export default LanguageSelector
