import React from "react"
import { LazyImage, Field, Icon } from "../../ui"

const IconRenderer = ({ domRef, info, value, ...props }) => {
    if (!value) return null

    return (
        <div ref={domRef} {...props}>
            {value.img && value.img.length > 0 && (
                <LazyImage src={value.img[0]} alt="" imageStyle="icon" />
            )}
            {value.icon && <Icon name={value.icon} />}
            <Field info={info} field="text" noadmin />
        </div>
    )
}
export default React.memo(IconRenderer)
