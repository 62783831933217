import React, { useState } from "react"
import { Entity, Field, FaIcon } from "../../../ui"

const JobRowRenderer = ({ info, row, index }) => {
    const [open, setOpen] = useState()
    const handleJobOpen = () => {
        setOpen(open => !open)
    }
    return (
        <Entity
            info={info}
            entity={row}
            className={`${open ? "open" : ""} ${index % 2 === 0 ? "even" : "odd"}`}
        >
            {info => (
                <>
                    <div className="job-section-short">
                        <div className="section-text">
                            <div>
                                <FaIcon icon="faUserCircle" size={48} className="job-title-icon" />
                                <Field info={info} field="title" tag="h2" />
                                <Field info={info} field="subtitle" tag="h3" />
                                <Field info={info} field="descr" />
                            </div>
                        </div>
                        <div className="section-handle">
                            <div>
                                <div className="job-handle" onClick={handleJobOpen}>
                                    <FaIcon
                                        icon={open ? "faMinusSquare" : "faPlusSquare"}
                                        size={48}
                                    />
                                    <p>detalii post</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="job-section-more">
                        <div className="section-in">
                            <Field info={info} field="mtitle" tag="h3" />
                            <Field
                                info={info}
                                field="mission"
                                iconBefore={{
                                    li: {
                                        icon: "faThumbsUp",
                                        size: 16,
                                    },
                                }}
                            />
                            <Field info={info} field="text" />
                            <Field
                                info={info}
                                field="footer"
                                iconAfter={{
                                    a: {
                                        icon: "faEnvelope",
                                        size: 20,
                                        className: "job-envelope",
                                    },
                                }}
                            />
                        </div>
                    </div>
                </>
            )}
        </Entity>
    )
}
export default JobRowRenderer
